import {PlatformLoader,} from '@flexinet/ui-components';
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {
    useEffect,
    useState
} from 'react';
import {UserLayout} from "./UserLayout.tsx";
import {PublicLayout} from "./PublicLayout.tsx";


export const AppLayout = () => {

    const [wait, setWait] = useState(true);


    const {
        email
    } = useUserAttributes()

    useEffect(() => {
        setTimeout(() => {
            setWait(false);
        }, 2000);
    }, []);

    if (wait) {
        return <PlatformLoader message={'Checking status...Please, wait!'}/>;
    }

    return email ? <UserLayout/> : <PublicLayout/>

};
