import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    PlatformAppShell,
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderToolbar,
    PlatformLoader,
    PlatformNavbarMode,
} from '@flexinet/ui-components';
import {
    Suspense,
    useState
} from "react";
import {PortalNavbar} from "./menu-items/pages/PortalNavbar.tsx";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {
    AiOutlineLayout,
    AiOutlineLogout
} from "react-icons/ai";
import {config} from "../config";
import {useDisclosure} from '@mantine/hooks';
import {ActionIcon} from '@mantine/core';


export const UserLayout = () => {

    const navigate = useNavigate()
    const [mode, setMode] = useState(PlatformNavbarMode.MEDIUM)
    const [navbarWidth, setNavbarWidth] = useState(310)
    const [opened, {toggle}] = useDisclosure();

    const {
        givenName,
        familyName,
        email
    } = useUserAttributes()


    let userInfo = undefined
    if (givenName) {
        userInfo = {
            name: [givenName, familyName].join(' '),
            email: email
        }
    }


    const additionalMenuItems = [{
        title: 'Sign Out',
        link: '/sign-out',
        onClick: () => navigate('/sign-out'),
        icon: <AiOutlineLogout/>
    }]

    const toolbar = <PlatformHeaderToolbar
        user={userInfo ? userInfo : undefined}
        portalUrl={config.platform.portal}
        additionalMenuItems={additionalMenuItems}
        additionalToolbarItems={[
            <ActionIcon
                size={'lg'}
                variant={'subtle'}
                title={'Toggle Navbar Layout'}
                onClick={toggle}
            >
                <AiOutlineLayout size={'1.8em'}/>
            </ActionIcon>,
        ]}

    />

    const header = <PlatformHeader toolbar={toolbar}/>

    const changeMode = (mode: PlatformNavbarMode) => {
        setMode(mode)
        if (mode === 'medium') {
            setNavbarWidth(310)
        } else {
            setNavbarWidth(70)
        }
    }

    return (
        <PlatformAppShell
            platformNavbar={<PortalNavbar mode={mode} changeMode={changeMode}/>}
            platformHeader={header}
            platformFooter={<PlatformFooter/>}
            header={{height: 80}}
            footer={{height: 100}}
            navbar={{
                width: navbarWidth,
                breakpoint: 'sm',
                collapsed: {
                    desktop: opened,
                    mobile: !opened
                }
            }}
        >

            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>

        </PlatformAppShell>
    );


};
