import {
    PlatformNavbar,
    PlatformNavbarMode
} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalNavbar = (
    {
        mode,
        changeMode
    }: {
        mode: PlatformNavbarMode,
        changeMode: (mode: PlatformNavbarMode) => void
    }
) => {

    const {allowedActions} = useVerifiedPermissions()

    const items = getMenuItems()


    const menuItems = items.filter((item) => {
        return allowedActions.includes(item.rbac as string)
    });


    return <PlatformNavbar
        appTitle={'Flexi Bot'}
        menuItems={menuItems}
        mode={mode}
        changeMode={changeMode}
    />

};