import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    FlexiSignInButton,
    PlatformAppShell,
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Suspense} from "react";
import {signInWithRedirect} from 'aws-amplify/auth';


export const PublicLayout = () => {
    const navigate = useNavigate()

    const menuItems = [{
        title: 'Home',
        onClick: () => navigate('/')
    }, {
        title: 'Products',
        onClick: () => navigate('/products')
    }, {
        title: 'Pricing',
        onClick: () => navigate('/pricing')
    }, {
        title: 'About',
        onClick: () => navigate('/about')
    }] as { title: string, onClick: () => void }[]

    const toolbar = <PlatformHeaderToolbar additionalToolbarItems={[
        <FlexiSignInButton
            onClick={() => {
                signInWithRedirect()
            }}>Sign in</FlexiSignInButton>,
    ]}/>

    const header = <PlatformHeader
        navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}
        toolbar={toolbar}/>

    return <PlatformAppShell
        platformHeader={header}
        platformFooter={<PlatformFooter/>}
    >
        <Suspense fallback={<PlatformLoader/>}>
            <Outlet/>
        </Suspense>
    </PlatformAppShell>


};
